// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-confirmation-tsx": () => import("./../../../src/pages/confirmation.tsx" /* webpackChunkName: "component---src-pages-confirmation-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-informed-consent-tsx": () => import("./../../../src/pages/informed-consent.tsx" /* webpackChunkName: "component---src-pages-informed-consent-tsx" */),
  "component---src-pages-soorten-beugels-tsx": () => import("./../../../src/pages/soorten-beugels.tsx" /* webpackChunkName: "component---src-pages-soorten-beugels-tsx" */),
  "component---src-pages-verloop-behandeling-tsx": () => import("./../../../src/pages/verloop-behandeling.tsx" /* webpackChunkName: "component---src-pages-verloop-behandeling-tsx" */)
}

